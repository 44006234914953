<template>
    <content-title link_field="联系"></content-title>

    <div class="main-content-box">
        <div style="max-width: 1250px;margin: 0 auto;">

            <div style="padding: 40px 0;">
                <div style="height: 400px;padding-bottom: 40px">
                    <div id="container"></div>
                </div>


                <div style="font-size: 25px;color: rgb(51, 51, 51);word-wrap: break-word;"><span>与我联系</span></div>
                <div class="input-box">
                    <div style="display:flex;justify-content: space-between;" class="info-box">
                        <div style="flex: 0 1 48%;"><el-input v-model="user_name" placeholder="姓名" minlength="10"></el-input></div>
                        <div style="flex: 0 1 48%;"><el-input v-model="user_wx" placeholder="微信"></el-input></div>
                    </div>

                    <div style="padding-top: 25px;" class="info-box"><el-input v-model="user_email" placeholder="邮箱"></el-input></div>

                    <div style="padding-top: 25px;" class="content-box"><el-input
                        type="textarea"
                        :rows="2"
                        placeholder="请输入内容"
                        v-model="user_content">
                    </el-input></div>

                    <div style="padding-top: 25px;" class="info-btn"><el-button @click="sendInfo">立即发送</el-button></div>

                </div>
            </div>

        </div>
    </div>

</template>

<script>
import ContentTitle from "../components/public/ContentTitle";
import qs from 'qs';
import { ElMessage } from 'element-plus';
export default {
    name: "Contact",
    components: {
        ContentTitle,
    },
    data() {
        return {
            user_name: '',
            user_wx: '',
            user_email: '',
            user_content: '',
        }
    },
    computed: {

    },
    methods: {
        sendInfo: function () {
            if (!this.user_name || !this.user_content){
                ElMessage.error('内容不可为空');
                return
            }


            let postData = {
                user_name: this.user_name,
                user_wx: this.user_wx,
                user_email: this.user_email,
                user_content: this.user_content,
            };
            let that = this;
            that.axios.post('/sendInfo', qs.stringify(postData), {headers: {'Content-Type': 'application/x-www-form-urlencoded'}})
                .then(function (response) {
                    ElMessage.success({
                        message: '发送成功',
                        type: 'success'
                    });
                    that.user_name = '';
                    that.user_wx = '';
                    that.user_email = '';
                    that.user_content = '';

                })
                .catch(function (error) {
                    ElMessage.error('发送失败，请重试');
                })
        },

    },

    created() {
        this.$nextTick(function(){
            var map = new BMap.Map("container", {
                coordsType: 5 // coordsType指定输入输出的坐标类型，3为gcj02坐标，5为bd0ll坐标，默认为5。
                              // 指定完成后API将以指定的坐标类型处理您传入的坐标
            });          // 创建地图实例
            var point = new BMap.Point(116.55016645789149, 39.759118187025365);  // 创建点坐标
            map.centerAndZoom(point, 8);                 // 初始化地图，设置中心点坐标和地图级别
            map.enableScrollWheelZoom(true);     //开启鼠标滚轮缩放

            var marker = new BMap.Marker(point);        // 创建标注
            map.addOverlay(marker);                     // 将标注添加到地图中
            marker.setAnimation(BMAP_ANIMATION_BOUNCE);   //动画

            var opts = {
                width : 10,     // 信息窗口宽度
                height: 10,     // 信息窗口高度
                title : "住址",  // 信息窗口标题
                enableMessage:true,  //设置允许信息窗发送短息
            }

            var infoWindow = new BMap.InfoWindow("马驹桥镇", opts);  // 创建信息窗口对象
            map.openInfoWindow(infoWindow, map.getCenter());      // 打开信息窗口


            addClickHandler('马驹桥镇',marker);
            function addClickHandler(content,marker){
                marker.addEventListener("click",function(e){
                    openInfo(content,e);
                });
            }
            function openInfo(content,e){
                var p = e.target;
                var point = new BMap.Point(p.getPosition().lng, p.getPosition().lat);
                var infoWindow = new BMap.InfoWindow(content,opts);// 创建信息窗口对象
                map.openInfoWindow(infoWindow,point);//开启信息窗口
            }


        })

    },

}
</script>
<style>
#container {width: 100%;height: 100%;overflow: hidden;margin:0;font-family:"微软雅黑";}

.input-box{
    padding: 20px 0;
}
.content-box .el-textarea__inner{
    min-height: 150px!important;
}

.info-box .el-input__inner{
    min-height: 50px!important;
}
.info-btn .el-button{
    min-height: 55px;
    color: #e74c3c;
    font-size: 15px;
    width: 100%;
}

.info-btn:hover .el-button{
    background-color: #EEE;
    border-color: #EEE;
}



/*.BMap_pop{*/
/*    left: 562px!important;*/
/*    top: 100px!important;*/
/*}*/

.BMap_top,.BMap_bottom{
    width: 85px!important;
}
.BMap_bottom{
    height: 0!important;
}

.BMap_pop div:nth-child(3){
    left: 110px!important;
}

.BMap_pop div:nth-child(5) div, .BMap_pop div:nth-child(7) div{
    height: 6px!important;
}

.BMap_pop img{
    left: 118px!important;
    top: 9px!important;
}
.BMap_center{
    width: 133px!important;
}

.BMap_pop div:nth-child(7){
    left: 110px!important;
}
.BMap_pop div:nth-child(8){
    top: 67px!important;
    left: 61px!important;
    display: none;
}

.BMap_pop div:nth-child(9){
    width: 102px!important;
}

.BMap_shadow{
    display: none;
}



</style>
